.clearfix {
	overflow: auto;
}
.clearfix:after {
	content: '';
	clear: both;
	display: table;
}

.wrapper {
	width: 100%;
}
.container {
	margin: 0 auto;
	padding: 0 0.9375rem;
}

@media only screen and (min-width: 1200px) {
	.container {
		width: 75rem;
	}
}

.container {
	@media only screen and (min-width: 576px) {
		max-width: 540px;
	}
	@media only screen and (min-width: 768px) {
		max-width: 720px;
	}
	@media only screen and (min-width: 992px) {
		max-width: 960px;
	}
	@media only screen and (min-width: 1200px) {
		max-width: 1140px;
	}
}

.content {
	margin-top: 0;
	@media only screen and (min-width: 576px) {
		margin-top: 4rem;
	}
}

.content-end {
	margin-bottom: 4rem;
	@media only screen and (min-width: 576px) {
		margin-bottom: 0;
	}
}
.btn {
	border: none;
}
.btn:focus {
	outline: none;
	box-shadow: none;
}
