.project {
  background-color: #fff;
  padding: 2rem 0;

  > .container {
    .project-title {
      &::before {
        content: url('../img/icons/processor.svg');
      }
    }

    .cards {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      .card {
        width: 20rem;
        margin: 1rem;
        background-color: $container-color;
        padding: 2rem;
        border-radius: 1rem;

        .card-title {
          display: flex;
          justify-content: space-between;

          .title-icon {
            width: 2rem;
            height: 2rem;
            transition: 0.4s;
          }
          .title-icon:hover {
            transition: 0.4s;
          }
        }
        .card-content {
          .card-content-title {
            color: $text-color;
            margin: 0.7rem 0;
          }
          .card-content-text {
            color: $text-color;
          }
        }

        .card-footer {
          margin: 1rem 0 2rem;
          padding: 0.5rem 0 1rem;
          line-height: 2;
          margin-bottom: 0;
          a {
            font-style: normal;
            font-weight: 700;
            font-size: 0.5rem;
            color: $text-color;
            font-size: 0.66rem;
            border: 3px solid $first-color;
            border-radius: 2rem;
            padding: 0.2rem 0.85rem 0.25rem;
            position: relative;
          }
          a:hover {
            background: linear-gradient(90deg, #ff8a00, #e52e71);
            text-shadow: none;
            -webkit-text-fill-color: transparent;
            -webkit-background-clip: text;
            -webkit-box-decoration-break: clone;
            background-clip: text;
            border-color: $body-color-alt;
            transition: 0.3s;
          }
        }
      }
      .card:hover {
        transform: scale(1.1);
        transition: 0.3s;

        .card-content {
          .card-content-title {
            color: $first-color-alt;
            transition: 0.3s;
          }
        }
      }
    }
    .project-footer {
      margin: 1rem;
      padding-bottom: 1rem;
      color: $text-color-light;
      text-align: center;
      a {
        color: $first-color-alt;
        img {
          width: 1rem;
          height: 1rem;
        }
      }
    }
  }
}
