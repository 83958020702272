@import "https://fonts.googleapis.com/css2?family=Noto+Sans+SC:wght@100;300;400;500;700;900&display=swap";
html {
  box-sizing: border-box;
  scroll-behavior: smooth;
  font-size: 100%;
}

* {
  margin: 0;
  padding: 0;
  font-family: Noto Sans SC, sans-serif;
  line-height: 1.6;
}

*, :before, :after {
  box-sizing: inherit;
}

a {
  color: #6d6a7c;
  text-decoration: none;
}

ul {
  list-style-type: none;
}

::-webkit-scrollbar {
  width: .5rem;
}

::-webkit-scrollbar-track {
  background: #ededed;
  border-radius: .2rem;
}

::-webkit-scrollbar-thumb {
  background-color: #9197a5;
  border-radius: .3rem;
}

html {
  background-color: #f5f8fa;
}

html.transition, html.transition *, html.transition :before, html.transition :after {
  transition: all .75s !important;
  transition-delay: 0 !important;
}

.clearfix {
  overflow: auto;
}

.clearfix:after {
  content: "";
  clear: both;
  display: table;
}

.wrapper {
  width: 100%;
}

.container {
  margin: 0 auto;
  padding: 0 .9375rem;
}

@media only screen and (width >= 1200px) {
  .container {
    width: 75rem;
  }
}

@media only screen and (width >= 576px) {
  .container {
    max-width: 540px;
  }
}

@media only screen and (width >= 768px) {
  .container {
    max-width: 720px;
  }
}

@media only screen and (width >= 992px) {
  .container {
    max-width: 960px;
  }
}

@media only screen and (width >= 1200px) {
  .container {
    max-width: 1140px;
  }
}

.content {
  margin-top: 0;
}

@media only screen and (width >= 576px) {
  .content {
    margin-top: 4rem;
  }
}

.content-end {
  margin-bottom: 4rem;
}

@media only screen and (width >= 576px) {
  .content-end {
    margin-bottom: 0;
  }
}

.btn {
  border: none;
}

.btn:focus {
  box-shadow: none;
  outline: none;
}

.generic-title {
  color: #3c4250;
  justify-content: center;
  align-items: center;
  margin: 2rem 0;
  display: flex;
}

.generic-title:before {
  width: 2rem;
  height: 2rem;
  margin: 0 1rem;
  display: block;
  position: relative;
}

.generic-title:after {
  content: "";
  background-color: #66f;
  width: 150px;
  height: 1px;
  margin-left: 1rem;
  display: none;
  position: relative;
}

@media only screen and (width >= 768px) {
  .generic-title:after {
    display: block;
  }
}

nav {
  z-index: 999;
  background-color: #f5f8fa;
  height: 4rem;
  position: fixed;
  bottom: 0;
  left: 0;
}

@media only screen and (width >= 576px) {
  nav {
    top: 0;
    left: 0;
  }
}

nav .container {
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  height: 4rem;
  display: flex;
}

nav .container .navbar-header {
  align-items: center;
  display: flex;
}

nav .container .navbar-header > a {
  font-weight: 500;
}

nav .container .navbar-header > a h4 {
  color: #3c4250;
}

nav .container .navbar-header > a h4 span {
  color: #6e57e0;
}

nav .container .navbar-header > a h4[data-theme="dark"] {
  color: #f0eefc;
}

nav .container .navbar-header > a h4[data-theme="dark"] span {
  color: #6e57e0;
}

nav .container .navbar-right {
  justify-content: space-evenly;
  display: flex;
}

nav .container .navbar-right .navbar-menu {
  display: none;
}

nav .container .navbar-right .navbar-menu .navbar-nav {
  flex-direction: column;
  display: flex;
}

nav .container .navbar-right .navbar-menu .navbar-nav > li > a:hover {
  color: #6e57e0;
}

@media only screen and (width >= 576px) {
  nav .container .navbar-right .navbar-menu {
    display: block !important;
  }

  nav .container .navbar-right .navbar-menu .navbar-nav {
    flex-direction: row;
    justify-content: end;
    align-items: center;
    margin-left: .5rem;
    display: flex;
  }

  nav .container .navbar-right .navbar-menu .navbar-nav > li {
    margin: 1rem;
  }

  nav .container .navbar-right .navbar-menu .navbar-nav > li > a:hover {
    color: #6e57e0;
  }
}

nav .container .navbar-right .navbar-menu[data-theme="dark"] .navbar-nav > li > a {
  color: #f0eefc;
}

nav .container .navbar-right .navbar-menu[data-theme="dark"] .navbar-nav > li > a:hover {
  color: #66f;
}

nav .container .navbar-right .navbar-toggler {
  cursor: pointer;
  background: url("apps.5b67cd82.svg");
  width: 1.5rem;
  height: 1.5rem;
}

@media only screen and (width >= 576px) {
  nav .container .navbar-right .navbar-toggler {
    display: none;
  }
}

nav .container .navbar-right .theme-switcher {
  cursor: pointer;
  background: url("moon.48bf4e8c.svg");
  border-radius: 50%;
  width: 1.5rem;
  height: 1.5rem;
  margin: auto 1rem;
}

nav .container .navbar-right .theme-switcher[data-theme="dark"] {
  background: url("sun.41504bf5.svg");
}

nav[data-theme="dark"] {
  background-color: #2e2e2e;
}

.secondary-nav {
  z-index: 999;
  background-color: #f5f8fa;
  display: none;
  position: fixed;
  bottom: 3rem;
  left: 0;
}

.secondary-nav .secondary-nav-container {
  justify-content: center;
  display: flex;
}

.secondary-nav .secondary-nav-container .secondary-nav-menu .secondary-nav-nav {
  text-align: center;
  margin: .4rem;
}

.secondary-nav .secondary-nav-container .secondary-nav-menu .secondary-nav-nav > li > a:hover {
  color: #6e57e0;
}

.secondary-nav .secondary-nav-container .secondary-nav-menu[data-theme="dark"] .secondary-nav-nav > li > a {
  color: #f0eefc;
}

.secondary-nav .secondary-nav-container .secondary-nav-menu[data-theme="dark"] .secondary-nav-nav > li > a:hover {
  color: #66f;
}

@media only screen and (width >= 576px) {
  .secondary-nav {
    display: none !important;
  }
}

.secondary-nav[data-theme="dark"] {
  background-color: #2e2e2e;
}

.home > .container {
  flex-wrap: wrap-reverse;
  justify-content: space-around;
  align-items: center;
  display: flex;
}

@media only screen and (width >= 450px) {
  .home > .container {
    height: 40rem;
  }
}

.home > .container .home-text {
  --typewriterSpeed: 5s;
  --typewriterCharacters: 15;
  flex: 2;
  margin: 2rem 0;
  display: flex;
}

.home > .container .home-text .home-links .home-links-icons {
  width: 1.5rem;
  height: 1.5rem;
  margin: 1rem 2rem 1rem 0;
}

.home > .container .home-text .home-text-text {
  z-index: -999;
}

.home > .container .home-text .home-text-text .home-title {
  font-family: Source Code Pro, monospace;
  font-size: 2rem;
  position: relative;
}

@media only screen and (width >= 450px) {
  .home > .container .home-text .home-text-text .home-title {
    width: max-content;
    margin: -5rem 0 1rem;
    font-size: 3rem;
  }

  .home > .container .home-text .home-text-text .home-title:before, .home > .container .home-text .home-text-text .home-title:after {
    content: "";
    position: absolute;
    inset: 0;
  }

  .home > .container .home-text .home-text-text .home-title:before {
    animation: typewriter var(--typewriterSpeed) steps(var(--typewriterCharacters)) 1s forwards;
    background: #f5f8fa;
  }

  .home > .container .home-text .home-text-text .home-title:after {
    animation: typewriter var(--typewriterSpeed) steps(var(--typewriterCharacters)) 1s forwards, blink .75s steps(var(--typewriterCharacters)) infinite;
    background: #000;
    width: .125em;
  }
}

.home > .container .home-text .home-text-text .home-subtitle {
  color: #6d6a7c;
  font-size: 1rem;
  font-weight: 400;
}

.home > .container .home-text .home-text-text .subtitle-text {
  font-size: .85rem;
}

@media only screen and (width >= 450px) {
  .home > .container .home-text .home-text-text .home-subtitle {
    color: #6d6a7c;
    opacity: 0;
    animation: fadeInUp 2s ease calc(var(--typewriterSpeed)  + 2s) forwards;
    font-size: 2rem;
    font-weight: 400;
    transform: translateY(3rem);
  }

  .home > .container .home-text .home-text-text .subtitle-text {
    font-size: 1rem;
  }
}

@keyframes typewriter {
  to {
    left: 100%;
  }
}

@keyframes blink {
  to {
    background: none;
  }
}

@keyframes fadeInUp {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.home > .container .home-text .home-text-text[data-theme="dark"] .home-title {
  color: #6e57e0;
}

.home > .container .home-text .home-text-text[data-theme="dark"] .home-title:before {
  background: #2e2e2e;
}

.home > .container .home-text .home-text-text[data-theme="dark"] .home-title:after {
  background: #fff;
}

.home > .container .home-text .home-text-text[data-theme="dark"] .home-subtitle {
  color: #f0eefc;
}

.home > .container .home-img {
  text-align: center;
  flex: 1;
}

.home > .container .home-img img {
  object-fit: cover;
  border-radius: 50%;
  width: 200px;
  height: 200px;
  margin: 1rem;
}

@media only screen and (width >= 400px) {
  .home > .container .home-img img {
    width: 300px;
    height: 300px;
  }
}

.language {
  background-color: #fff;
  padding: 2rem 0;
}

.language > .container .language-title:before {
  content: url("laptop.819c4461.svg");
}

.language > .container .language-title:after {
  width: 80px;
}

.language > .container .language-img-container {
  max-width: 600px;
  margin: 0 auto;
}

.language > .container .language-img-container .language-images {
  flex-wrap: wrap;
  justify-content: center;
  display: flex;
}

.language > .container .language-img-container .language-images img {
  margin: 1rem;
}

.language > .container .language-img-container .language-images img:hover {
  transition: all .3s;
  transform: scale(1.1);
}

[data-theme="dark"] .language {
  background-color: #353535;
}

.framework-tools {
  padding: 2rem 0;
}

.framework-tools > .container {
  flex-wrap: wrap;
  justify-content: center;
  display: flex;
}

.framework-tools > .container .framework-container {
  margin: 1rem;
}

.framework-tools > .container .framework-container .framework-title:before {
  content: url("monitor.0a88c50b.svg");
}

.framework-tools > .container .framework-container .framework-title:after {
  width: 80px;
}

.framework-tools > .container .framework-container .framework-img-container {
  max-width: 380px;
  margin: 0 auto;
}

.framework-tools > .container .framework-container .framework-img-container .framework-images {
  flex-wrap: wrap;
  justify-content: center;
  display: flex;
}

.framework-tools > .container .framework-container .framework-img-container .framework-images img {
  margin: 1rem;
}

.framework-tools > .container .framework-container .framework-img-container .framework-images img:hover {
  transition: all .3s;
  transform: scale(1.1);
}

.framework-tools > .container .tools-container {
  margin: 1rem;
}

.framework-tools > .container .tools-container .tools-title:before {
  content: url("tools.d0fd440f.svg");
}

.framework-tools > .container .tools-container .tools-title:after {
  width: 80px;
}

.framework-tools > .container .tools-container .tools-img-container {
  max-width: 380px;
  margin: 0 auto;
}

.framework-tools > .container .tools-container .tools-img-container .tools-images {
  flex-wrap: wrap;
  justify-content: center;
  display: flex;
}

.framework-tools > .container .tools-container .tools-img-container .tools-images img {
  margin: 1rem;
}

.framework-tools > .container .tools-container .tools-img-container .tools-images img:hover {
  transition: all .3s;
  transform: scale(1.1);
}

.project {
  background-color: #fff;
  padding: 2rem 0;
}

.project > .container .project-title:before {
  content: url("processor.4aa4065f.svg");
}

.project > .container .cards {
  flex-wrap: wrap;
  justify-content: center;
  display: flex;
}

.project > .container .cards .card {
  background-color: #f5f8fa;
  border-radius: 1rem;
  width: 20rem;
  margin: 1rem;
  padding: 2rem;
}

.project > .container .cards .card .card-title {
  justify-content: space-between;
  display: flex;
}

.project > .container .cards .card .card-title .title-icon {
  width: 2rem;
  height: 2rem;
  transition: all .4s;
}

.project > .container .cards .card .card-title .title-icon:hover {
  transition: all .4s;
}

.project > .container .cards .card .card-content .card-content-title {
  color: #6d6a7c;
  margin: .7rem 0;
}

.project > .container .cards .card .card-content .card-content-text {
  color: #6d6a7c;
}

.project > .container .cards .card .card-footer {
  margin: 1rem 0 0;
  padding: .5rem 0 1rem;
  line-height: 2;
}

.project > .container .cards .card .card-footer a {
  color: #6d6a7c;
  border: 3px solid #6e57e0;
  border-radius: 2rem;
  padding: .2rem .85rem .25rem;
  font-size: .66rem;
  font-style: normal;
  font-weight: 700;
  position: relative;
}

.project > .container .cards .card .card-footer a:hover {
  text-shadow: none;
  -webkit-text-fill-color: transparent;
  background: linear-gradient(90deg, #ff8a00, #e52e71);
  -webkit-background-clip: text;
  -webkit-box-decoration-break: clone;
  -webkit-background-clip: text;
  background-clip: text;
  border-color: #8ecae6;
  transition: all .3s;
}

.project > .container .cards .card:hover {
  transition: all .3s;
  transform: scale(1.1);
}

.project > .container .cards .card:hover .card-content .card-content-title {
  color: #66f;
  transition: all .3s;
}

.project > .container .project-footer {
  color: #a19fad;
  text-align: center;
  margin: 1rem;
  padding-bottom: 1rem;
}

.project > .container .project-footer a {
  color: #66f;
}

.project > .container .project-footer a img {
  width: 1rem;
  height: 1rem;
}

html[data-theme="dark"] {
  background-color: #2e2e2e;
}

html[data-theme="dark"] .generic-title {
  color: #f0eefc;
}

html[data-theme="dark"] .generic-title:after {
  background-color: #f0eefc;
}

html[data-theme="dark"] .project {
  background-color: #353535;
}

html[data-theme="dark"] .project > .container .project-title {
  color: #f0eefc;
}

html[data-theme="dark"] .project > .container .project-title:after {
  background-color: #f0eefc;
}

html[data-theme="dark"] .project > .container .cards .card {
  background-color: #454851;
}

html[data-theme="dark"] .project > .container .cards .card .card-content .card-content-title, html[data-theme="dark"] .project > .container .cards .card .card-content .card-content-text {
  color: #f0eefc;
}

html[data-theme="dark"] .project > .container .cards .card .card-footer a {
  color: #9197a5;
  border: 3px solid #9197a5;
}

html[data-theme="dark"] .project > .container .cards .card .card-footer a:hover {
  border-color: #6e57e0;
}

/*# sourceMappingURL=index.c9b17f9c.css.map */
