.framework-tools {
  padding: 2rem 0;

  > .container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    .framework-container {
      margin: 1rem;

      .framework-title {
        &::before {
          content: url('../img/icons/monitor.svg');
        }

        &::after {
          width: 80px;
        }
      }

      .framework-img-container {
        max-width: 380px;
        margin: 0 auto;

        .framework-images {
          display: flex;
          justify-content: center;
          flex-wrap: wrap;

          img {
            margin: 1rem;

            &:hover {
              transform: scale(1.1);
              transition: 0.3s;
            }
          }
        }
      }
    }

    .tools-container {
      margin: 1rem;

      .tools-title {
        &::before {
          content: url('../img/icons/tools.svg');
        }
        &::after {
          width: 80px;
        }
      }

      .tools-img-container {
        max-width: 380px;
        margin: 0 auto;

        .tools-images {
          display: flex;
          justify-content: center;
          flex-wrap: wrap;

          img {
            margin: 1rem;

            &:hover {
              transform: scale(1.1);
              transition: 0.3s;
            }
          }
        }
      }
    }
  }
}
