nav {
	position: fixed;
	bottom: 0;
	left: 0;
	background-color: $body-background-light;
	height: 4rem;
	z-index: 999;

	@media only screen and (min-width: 576px) {
		top: 0;
		left: 0;
	}
	.container {
		height: 4rem;
		display: flex;
		flex-wrap: nowrap;
		align-items: center;
		justify-content: space-between;

		.navbar-header {
			display: flex;
			align-items: center;

			> a {
				font-weight: 500;

				h4 {
					color: $title-color;
					span {
						color: $first-color;
					}
				}
				h4[data-theme='dark'] {
					color: $text-color-alt;
					span {
						color: $first-color;
					}
				}
			}
		}

		.navbar-right {
			display: flex;

			justify-content: space-evenly;

			.navbar-menu {
				display: none;
				.navbar-nav {
					display: flex;
					flex-direction: column;
					> li {
						> a:hover {
							color: $first-color;
						}
					}
				}

				@media only screen and (min-width: 576px) {
					display: block !important;
					.navbar-nav {
						display: flex;
						flex-direction: row;
						align-items: center;
						justify-content: end;
						margin-left: 0.5rem;

						> li {
							margin: 1rem;
							> a:hover {
								color: $first-color;
							}
						}
					}
				}
			}
			.navbar-menu[data-theme='dark'] {
				.navbar-nav {
					> li {
						> a {
							color: $text-color-alt;
						}
						> a:hover {
							color: $first-color-alt;
						}
					}
				}
			}

			.navbar-toggler {
				background: url('../img/icons/apps.svg');
				width: 1.5rem;
				height: 1.5rem;
				cursor: pointer;
				@media only screen and (min-width: 576px) {
					display: none;
				}
			}

			.theme-switcher {
				margin: auto 1rem;
				cursor: pointer;
				width: 1.5rem;
				height: 1.5rem;
				border-radius: 50%;
				background: url('../img/icons/moon.svg');
			}

			.theme-switcher[data-theme='dark'] {
				background: url('../img/icons/sun.svg');
			}
		}
	}
}

nav[data-theme='dark'] {
	background-color: $body-background-dark;
}

.secondary-nav {
	display: none;
	position: fixed;
	bottom: 3rem;
	left: 0;
	background-color: $body-background-light;
	z-index: 999;
	.secondary-nav-container {
		display: flex;
		justify-content: center;
		.secondary-nav-menu {
			.secondary-nav-nav {
				text-align: center;
				margin: 0.4rem;
				> li {
					> a:hover {
						color: $first-color;
					}
				}
			}
		}
		.secondary-nav-menu[data-theme='dark'] {
			.secondary-nav-nav {
				> li {
					> a {
						color: $text-color-alt;
					}
					> a:hover {
						color: $first-color-alt;
					}
				}
			}
		}
	}

	@media only screen and (min-width: 576px) {
		display: none !important;
	}
}

.secondary-nav[data-theme='dark'] {
	background-color: $body-background-dark;
}
