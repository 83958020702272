$body-background-light: #f5f8fa;
$body-background-dark: #2e2e2e;
$body-background-alt: #353535;

$hover-background: #ededed;
$sidebar-background: #fff;
$scrollbar-background: #9197a5;

$first-color: #6e57e0;
$first-color-alt: #66f;

$title-color: #3c4250;

$text-color: #6d6a7c;
$text-color-alt: #f0eefc;

$text-color-light: #a19fad;
$text-color-light-alt: #9197a5;

$body-color: #fbfbfe;
$body-color-dark: #454851;
$body-color-alt: #8ecae6;

$container-color: #f5f8fa;
