.home {
	> .container {
		display: flex;
		justify-content: space-around;
		align-items: center;
		flex-wrap: wrap-reverse;

		@media only screen and (min-width: 450px) {
			height: 40rem;
		}

		.home-text {
			flex: 2;
			--typewriterSpeed: 5s;
			--typewriterCharacters: 15;
			margin: 2rem 0;
			display: flex;

			.home-links {
				.home-links-icons {
					width: 1.5rem;
					height: 1.5rem;
					margin: 1rem 2rem 1rem 0;
				}
			}

			.home-text-text {
				z-index: -999;

				.home-title {
					font-size: 2rem;
					position: relative;
					font-family: 'Source Code Pro', monospace;
				}
				@media only screen and (min-width: 450px) {
					.home-title {
						margin: -5rem 0 1rem 0;
						width: max-content;
						font-size: clamp(1rem, 3rem, 4rem);
					}
					.home-title::before,
					.home-title::after {
						content: '';
						position: absolute;
						top: 0;
						right: 0;
						bottom: 0;
						left: 0;
					}
					.home-title::before {
						background: $body-background-light;
						animation: typewriter var(--typewriterSpeed) steps(var(--typewriterCharacters)) 1s forwards;
					}

					.home-title::after {
						width: 0.125em;
						background: black;
						animation: typewriter var(--typewriterSpeed) steps(var(--typewriterCharacters)) 1s forwards,
							blink 750ms steps(var(--typewriterCharacters)) infinite;
					}
				}

				.home-subtitle {
					color: $text-color;
					font-size: 1rem;
					font-weight: 400;
				}
				.subtitle-text {
					font-size: 0.85rem;
				}

				@media only screen and (min-width: 450px) {
					.home-subtitle {
						color: $text-color;
						font-size: 2rem;
						font-weight: 400;
						opacity: 0;
						transform: translateY(3rem);
						animation: fadeInUp 2s ease calc(var(--typewriterSpeed) + 2s) forwards;
					}
					.subtitle-text {
						font-size: 1rem;
					}
				}
				@keyframes typewriter {
					to {
						left: 100%;
					}
				}

				@keyframes blink {
					to {
						background: transparent;
					}
				}

				@keyframes fadeInUp {
					to {
						opacity: 1;
						transform: translateY(0);
					}
				}
			}

			.home-text-text[data-theme='dark'] {
				.home-title {
					color: $first-color;
				}
				.home-title::before {
					background: $body-background-dark;
				}
				.home-title::after {
					background: #fff;
				}
				.home-subtitle {
					color: $text-color-alt;
				}
			}
		}
		.home-img {
			flex: 1;
			text-align: center;

			img {
				object-fit: cover;
				border-radius: 50%;
				height: 200px;
				width: 200px;
				margin: 1rem;
			}
			@media only screen and (min-width: 400px) {
				img {
					height: 300px;
					width: 300px;
				}
			}
		}
	}
}
