.generic-title {
  color: $title-color;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2rem 0;

  &::before {
    display: block;
    position: relative;
    width: 2rem;
    height: 2rem;
    margin: 0 1rem;
  }

  &::after {
    content: '';
    display: none;
    position: relative;
    width: 150px;
    height: 1px;
    margin-left: 1rem;
    background-color: $first-color-alt;

    @media only screen and (min-width: 768px) {
      display: block;
    }
  }
}
